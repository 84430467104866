<template>
  <div>
    <dashboard-page-title :showAddBtn="false" :showFilter="true" :showSearch="true" :showExportation="true">الرحلات</dashboard-page-title>

    <main-table :fields="fields" :baseURL="VUE_APP_TRANSPORTATION_LINK" :list_url="'trip-requests'"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_TRANSPORTATION_LINK } from '@/config/constant'
export default {
  name: 'processes',
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_TRANSPORTATION_LINK,
      fields: [
        { label: 'رقم الطلب', key: 'logo', type: 'image', class: 'text-right' },
        { label: 'اسم العميل', key: 'cover', type: 'image', class: 'text-right' },
        { label: 'عدد المتون', key: 'name', class: 'text-right' },
        { label: 'إجمالى التكلفة', key: 'status', type: 'status', class: 'text-right' },
        { label: 'تاريخ النقل', key: 'created_at', class: 'text-right' },
        { label: 'الفاتورة', key: 'receipt', class: 'text-right' },
        {
          label: 'التحكم',
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              text: 'view',
              icon: 'ri-eye-fill',
              color: 'primary'
              // ifNavigate: true,
              // routePath: 'show-product/:id'
            },
            {
              text: 'edit',
              icon: 'ri-ball-pen-fill',
              color: 'info',
              ifNavigate: false
              // routePath: 'edit-product/:id'
            }
            // {
            //   text: 'Delete',
            //   icon: 'ri-delete-bin-line',
            //   color: 'danger',
            //   url: 'merchant/products',
            //   titleHeader: 'منتج',
            //   question: 'هل متأكد أنك تريد مسح هذا المنتج',
            //   textContent: 'name',
            //   textDeleteButton: 'بالتأكيد, مسح',
            //   textCancelButton: 'الغاء',
            //   showAlert: true
            // }
          ]
        }
      ]
    }
  },
  methods: {
    addMerchant () {
      console.log('hello')
    }
  }
}
</script>
